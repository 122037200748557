import styled from "styled-components";

const Title = styled.h2`
    margin-bottom: 15px;
    color: ${({ colorValue }) => colorValue};
    line-height: 32px;
    font-size: 28px;
    font-weight: 800;
    text-align: ${({ alignmentValue }) => alignmentValue};
`; 

const SectionTitle = ({ titleColor = '#000', alignment = 'center', children}) => {
    return <Title colorValue={titleColor} alignmentValue={alignment}>{children}</Title>
}

export default SectionTitle